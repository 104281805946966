import React from 'react'

import styles from './gallery-2.module.css'

const BlogPost = (props) => {
  return (
    <div className={styles['container']}>
      <div className={styles['gallery']}>
        <div className={styles['container01']}>
          <div className={styles['container02']} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
            <h1 className={styles['text']}>Construction Services</h1>
            <span className={styles['text01']}>
              <span>From new construction to renovations, our experienced team provides the expertise and resources you need to complete your project on time and within budget.</span>
            </span>
          </div>
        </div>
        <div className={styles['container03']}>
          <div className={styles['container04']} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
            <h1 className={styles['text03']}>Consultancy Services</h1>
            <span className={styles['text04']}>
              <span>Our consulting services help you optimize your operations, minimize risks, and ensure compliance with industry standards and regulations.</span>
            </span>
          </div>
        </div>
        <div className={styles['container05']}>
          <div className={styles['container06']} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
            <h1 className={styles['text06']}>Training</h1>
            <span className={styles['text07']}>
              <span>Our practical, hands-on training programs cover everything from project management to technical skills development.</span>
            </span>
          </div>
        </div>
        <div className={styles['container07']}>
          <div className={styles['container08']} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
            <h1 className={styles['text09']}>Laser Screed Flooring</h1>
            <span className={styles['text10']}>
              <span>Our industrial laser screed flooring solutions are durable, easy to maintain, and can be customized to fit your unique requirements.</span>
            </span>
          </div>
        </div>
        <div className={styles['container09']}>
          <div className={styles['container10']} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
            <h1 className={styles['text12']}>Waterproofing Services</h1>
            <span className={styles['text13']}>
              <span>Our waterproofing solutions provide long-lasting protection against water damage, tailored to your specific needs.</span>
            </span>
          </div>
        </div>
        <div className={styles['container11']}>
          <div className={styles['container12']} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
            <h1 className={styles['text15']}>Structural Repair Services</h1>
            <span className={styles['text16']}>
              <span>Our cost-effective Structural repair services restore your structures to their original condition, ensuring they remain safe and functional.</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPost
