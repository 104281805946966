import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Profile from './views/profile'
import Home from './views/home'
import BlogPost from './views/blog-post'
import Clients from './views/clients'
import Products from './views/products'
import SingleCategory from './views/singleCategory.jsx'
import Contact from './views/contact'
import Services from './views/services'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Products} exact path="/products" />
        <Route component={SingleCategory} path="/products/:category" />
        <Route component={Profile} path='/about' />
        <Route component={Home} exact path="/" />
        <Route component={Clients} exact path="/clients" />
        <Route component={Contact} path="/contact" />
        <Route component={Services} path="/services" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))