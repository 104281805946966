import React from 'react'

import PropTypes from 'prop-types'

import './member-details.css'

const MemberDetails = (props) => {
  return (
    <div className="member-details-container">
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="member-details-image"
      />
      <h1 className="member-details-text TextXL">{props.heading1}</h1>
      <h1 className="member-details-text1 TextSM" style={{marginBottom: "1rem"}}>{props.heading11}</h1>
      {/* <h1 className='member-details-text2 TextSM'>{props.desc}</h1> */}
      {
        props.desc.map((item, index) => {
          return (
            <span style={{
              // alignSelf: 'flex-start',
              display: 'block',
              textAlign: 'left',
              fontSize: '0.8rem',
              fontWeight: '400',
              lineHeight: '1.1rem',
              color: '#333',
            }} key={index}>- {item}</span>
          )
        })
      }
    </div>
  )
}

MemberDetails.defaultProps = {
  heading11: 'WEB DEVELOPER',
  heading1: 'Ryan Tompson',
  image_alt: 'image',
  image_src: '/playground_assets/mp1-200h.jpeg',
}

MemberDetails.propTypes = {
  heading11: PropTypes.string,
  heading1: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
}

export default MemberDetails
