import React, { useEffect, useState } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { LocationOn, Call, Mail } from '@mui/icons-material'
import './contact.css'
import { Helmet } from 'react-helmet'

function Contact() {

    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [message, setMessage] = useState('')

    async function submit() {
        const response = await fetch("https://dura-materials-default-rtdb.asia-southeast1.firebasedatabase.app/leads.json", {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const data = await response.json()

        if (data) {
            alert('Message Sent Successfully!')
        }
    }

   

  return (
    <div className='contact-container'>
        <div style={{
            backgroundColor: '#1e293bff',
        }}>
            <Helmet>
        <title>Contact Us</title>
        <meta property="og:title" content="Contact Us" />
        <meta property="og:description" content="Dura Materials and Services LLP is a leading supplier of construction materials and services in India. We are a one-stop solution for all your construction needs." />
        <meta property="og:image" content="/playground_assets/dura.png" />
      </Helmet>
            <Header />
        </div>
        <div className='contact-details' id='contact-details'>
            <div className='contact-details-container'>
                <div className='contact-details-left'>
                    <div className='contact-details-left-container' style={{
                        paddingLeft: 30,
                        paddingRight: 30
                    }}>
                        <div className='contact-details-left-container-title'>
                            <h1>CONTACT US</h1>

                            <p className='contact-subline2'>
                                We are always happy to hear from you. Please
                                contact us for any queries or feedback.
                            </p>

                            <div className='contact-details-left-container-title-address'>
                                <div className="contact-item">
                                    <LocationOn style={{ fontSize: 40 }} />
                                    <h3>Address</h3>
                                    <p>
                                    102 Road No. 4, Defence Layout, Vidyaranyapura, Bengaluru, Karnataka
                                    560078
                                    </p>
                                </div>

                                <div className="contact-item">
                                    <Call style={{ fontSize: 40 }} />
                                    <h3>Phone</h3>
                                    <a href="tel:+919663106061">
                                        <p>+91 96631 06061</p>
                                    </a>
                                    <a href="tel:+919845697663">
                                        <p>+91 98456 97663</p>
                                    </a>
                                </div>

                                <div className="contact-item">
                                    <Mail style={{ fontSize: 40 }} />
                                    <h3>Email</h3>
                                    <p>
                                        <a href='mailto:info@duramaterials.in'>
                                            Mail Us At: info@duramaterials.in
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contact-details-right'>
                    <div className='contact-details-right-container'>
                        <div className='contact-details-right-container-title' style={{
                    paddingLeft: 60,
                    paddingRight: 60
                }}>
                            <h1>Get in Touch</h1>
                            <p>
                                We are always happy to hear from you. Please
                                contact us for any queries or feedback.
                            </p>
                            
                            <div>
                                <div className='contact-details-right-container-title-input'>
                                    <input
                                        type='text'
                                        name='name'
                                        placeholder='Name'
                                        required
                                        value={name}
                                        onChange={(e)=>setName(e.target.value)}
                                    />
                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr",
                                        gap: 18
                                    }}
                                    >
                                    <input
                                        type='email'
                                        name='email'
                                        placeholder='Email'
                                        required
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                    <input
                                        type='phone'
                                        name='phone'
                                        placeholder='Phone Number'
                                        required
                                        value={phone}
                                        onChange={(e)=>setPhone(e.target.value)}
                                    />
                                    </div>
                                    <input
                                        type='company'
                                        name='company'
                                        placeholder='Your Company Name'
                                        required
                                        value={company}
                                        onChange={(e)=>setCompany(e.target.value)}
                                    />
                                    <textarea
                                        name='message' 
                                        placeholder='Message'
                                        required
                                        value={message}
                                        onChange={(e)=>setMessage(e.target.value)}
                                    ></textarea>

                                    <button onClick={submit} type='submit'>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Contact