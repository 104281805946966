import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'
import { Link } from 'react-router-dom'

const Footer = (props) => {
  return (
    <div className={`footer-container ${props.rootClassName} `}>
      <div className="footer-container01">
        <div className="footer-container02">
          <h1 className="footer-text TextXL">
            <span className="">Let&apos;s keep in touch</span>
          </h1>
          <span className="footer-text02">
            Find out on any of these platforms, we respond 1-2 business days.
          </span>
          <div className="footer-container03">
              {/* <Link to="/about"> */}
              <a href="mailto:info@duramaterials.in">
            <div className="footer-container04">
              <svg viewBox="0 0 1024 1024" className="footer-icon">
                <path
                  d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                  className=""
                ></path>
              </svg>
            </div>
              </a>
              {/* </Link> */}
              {/* <Link to="/about"> */}
              <a href="tel:+919663106061">
            <div className="footer-container05">
              <svg viewBox="0 0 1024 1024" className="footer-icon2">
                <path
                  d="M854 656q18 0 30 12t12 30v148q0 50-42 50-298 0-512-214t-214-512q0-42 50-42h148q18 0 30 12t12 30q0 78 24 150 8 26-10 44l-82 72q92 192 294 290l66-84q12-12 30-12 10 0 14 2 72 24 150 24z"
                  className=""
                ></path>
              </svg>
            </div>
              </a>
              {/* </Link> */}
            <Link to="/about">
            <div className="footer-container06">
              <svg viewBox="0 0 1024 1024" className="footer-icon4">
                <path
                  d="M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h416v-448h-128v-128h128v-64c0-105.8 86.2-192 192-192h128v128h-128c-35.2 0-64 28.8-64 64v64h192l-32 128h-160v448h288c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96z"
                  className=""
                ></path>
              </svg>
            </div>
              </Link>
              <Link to="/about">
            <div className="footer-container07">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="footer-icon6"
              >
                <path
                  d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                  className=""
                ></path>
              </svg>
            </div>
              </Link>
          </div>
        </div>
        <div className="footer-container08">
          <div className="footer-container09">
            <span className="footer-text03 TextXS">USEFUL LINK</span>
            <Link to="/about">
              <span className="footer-text04 TextSM">About Us</span>
            </Link>
            <Link to="/products">
              <span className="footer-text05 TextSM">Products</span>
            </Link>
            <Link to='/services'>
              <span className="footer-text06 TextSM">Services</span>
            </Link>
            <Link to="/clients#">
              <span className="footer-text06 TextSM">Clients</span>
            </Link>
          </div>
          <div className="footer-container10">
            <span className="footer-text08 TextXS">OTHER RESOURCES</span>
            <a href="/about/#directors" className="footer-text09 TextSM">
              Our Directors
            </a>
            {/* <a href="/#testimonials" className="footer-text10 TextSM">Testimonials</a> */}
            <Link to="/contact" className="footer-text12 TextSM">Contact Us</Link>
          </div>
        </div>
      </div>
      <div className="footer-container11"></div>
      <span className="footer-text13 TextSM"  style={{textAlign: 'center'}}>
        {/* Copyright © 2021 Dura Materials and Services LLP. */}
        <a href="https://externs.tech/" className="footer-text1 TextSM" style={{color: "#ccc"}}>
          Website powered by Externs Technologies
        </a>
      </span>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: '',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
}

export default Footer
